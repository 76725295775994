import React, { Component, Fragment } from 'react';
import randomcolor from 'randomcolor';
import { Link } from 'react-router-dom';
import OrderLine from './OrderLine';
import OrderFields from './OrderFields';
import Address from './Address';
import AbcOrder from './AbcOrder';
import Switch from '../Switch';
import DeliveryForm from '../edi/DeliveryForm';
import { cbcmToCbm, inPallets, getSize } from '../../dimensions';
import { updateOrder, updateOrders, changeState } from '../../websocket';
import { splitLocation, stringifyLocation } from '../../helper';
import sortLocation from '../../locationSorter';
import { printStickers, updateAddresses } from '../../api';
import '../../css/planpage.css';

const NO_LOCATIONS = { ONBEKEND: 0 };

class PlanPage extends Component {
	constructor(props) {
		super(props);
		const { order } = this.props;
		this.state = {
			pallets: order && order.maxPallets || '',
			printing: false
		};
		this.setPallets = this.setPallets.bind(this);
		this.printStickers = this.printStickers.bind(this);
		this.toggleDropship = this.toggleDropship.bind(this);
		this.updateAddress = this.updateAddress.bind(this);
	}

	componentDidUpdate(props) {
		const currentOrder = this.props.order;
		const previousOrder = props.order;
		if(currentOrder !== previousOrder && currentOrder.maxPallets !== this.state.pallets) {
			this.setState({ pallets: currentOrder.maxPallets || '' });
		}
	}

	setPallets(e) {
		let pallets = e.target.value;
		this.setState({ pallets });
		const { order } = this.props;
		if(pallets === '') {
			pallets = null;
		} else {
			pallets = parseInt(pallets, 10);
		}
		if(order.maxPallets !== pallets && (pallets === null || !isNaN(pallets))) {
			updateOrder(order._id, 'maxPallets', pallets);
		}
	}

	toggleAllLines(status) {
		const { order } = this.props;
		order.lines.forEach(line => {
			const location = Object.keys(line.locations).find(key => line.locations[key] >= line.quantity);
			if(location) {
				changeState(order._id, line._id, location, status);
			}
		});
	}

	toggleDropship(e) {
		const { order } = this.props;
		updateOrder(order._id, 'dropship', e.target.checked);
	}

	printStickers(e) {
		e.preventDefault();
		this.setState({ printing: true });
		printStickers(this.props.order._id).then(() => {
			window.alert('Printopdracht verstuurd');
		}).catch(err => {
			console.log('Failed to print stickers', err);
		}).then(() => {
			this.setState({ printing: false });
		});
	}

	updateAddress(e) {
		e.preventDefault();
		updateAddresses(this.props.abc.map(o => o._id)).then(() => {
			window.alert('Gesynchroniseerd met NAV');
		}).catch(err => {
			console.log('Failed to update address', err);
		});
	}

	render() {
		const { order, person, abc } = this.props;
		const switches = [];
		if(['PICKER', 'TEAM_LEADER'].includes(person.role) && ['ON_PALLET', 'NONE'].includes(order.status)) {
			switches.push(<p>
				{order.dropship ? 'Gepakt' : 'Op pallet'} <Switch
					value={order.status === 'ON_PALLET'}
					onChange={e => {
						const status = e.target.checked ? 'ON_PALLET' : 'NONE';
						let orders;
						if(e.target.checked && abc.length) {
							orders = abc.filter(o => o.lines.some(line => line.status !== 'NONE'));
							if(!orders.length) {
								return;
							}
						} else {
							orders = abc;
						}
						updateOrders(orders.map(o => o._id), 'status', status);
					}} />
			</p>);
		}
		const hasPallets = abc.some(o => o.pallets.length);
		if(['ON_PALLET', 'SEALING'].includes(order.status)) {
			if(person.role === 'PACKAGER') {
				switches.push(<p>
					Automatisch aanmelden <Switch
						value={!order.noRegistration}
						onChange={e => {
							updateOrders(abc.map(o => o._id), 'noRegistration', !e.target.checked);
						}} />
				</p>);
				switches.push(<p>
					Ingepakt <Switch
						value={order.status === 'SEALING'}
						disabled={order.status === 'ON_PALLET' && !hasPallets}
						onChange={e => {
							const status = e.target.checked ? 'SEALING' : 'ON_PALLET';
							updateOrders(abc.map(o => o._id), 'status', status);
						}} />
				</p>);
			} else if(person.role === 'TEAM_LEADER' && !order.dropship) {
				switches.push(<p>
					Sealing <Switch
						value={order.status === 'SEALING'}
						onChange={e => {
							const status = e.target.checked ? 'SEALING' : 'ON_PALLET';
							updateOrders(abc.map(o => o._id), 'status', status);
						}} />
				</p>);
			}
		}
		const status = switches.length ? React.createElement('div', { className: 'order-status' }, ...switches) : null;
		const locations = {};
		const states = {
			NONE: 0,
			SUCCESS: 0,
			FAILED: 0
		};
		let size = 0;
		const notified = [];
		abc.forEach(o => {
			o.lines.forEach(line => {
				const num = Object.keys(line.locations).length;
				if(num || person.role === 'CUSTOMER_SERVICE' || line.status !== 'NONE') {
					states[line.status]++;
					const locs = num ? line.locations : NO_LOCATIONS;
					Object.keys(locs).forEach(key => {
						if(key !== 'INPAK' || num === 1) {
							if(!locations[key]) {
								locations[key] = [];
							}
							locations[key].push({ line, quantity: locs[key], order: o });
						}
					});
					size += getSize(line);
				}
			});
			if(o.lastInformed) {
				notified.push(new Date(o.lastInformed).toLocaleString());
			}
		});
		const buttons = [
			<Link key="slip" to={`/order/${order._id}/slip`} className="btn btn-primary">Pakbon</Link>
		];
		if(order.status !== 'NONE' && (order.noStickers || person.role === 'CUSTOMER_SERVICE' || hasPallets)) {
			if(order.status === 'ON_PALLET') {
				buttons.push(' ', <Link key="stickers" to={`/order/${order._id}/stickers`} className="btn btn-primary">Stickers</Link>);
			}
			if(states.NONE) {
				buttons.push(<br />, <span className="text-danger">Niet alle regels zijn geselecteerd</span>);
			}
		}
		if(order.status !== 'DONE' && person.role === 'CUSTOMER_SERVICE') {
			buttons.unshift(<button key="updateAddress" className="btn btn-primary" onClick={this.updateAddress}>Sync adres</button>, ' ');
		}
		const creators = abc.map(({ creator }) => creator).filter(c => c);
		let prev;
		return <section className={order.dropship ? 'dropship-order container' : 'container'}>
			<div>
				{order.dropship && person.role !== 'CUSTOMER_SERVICE' && person.role !== 'PACKAGER' ? <h1>Dropship order</h1> : <Fragment>
					<h1>{abc.map(({ code }) => code).join(' ')}</h1>
					{order.dropship ? <p>Dropship order</p>
						: order.noStickers && <p>B2C of zelf-ophaalorder</p>}
				</Fragment>}
				{notified.length ? <p>Laatst naar de klant gestuurd op {notified.join(', ')}</p> : null}
				{['CUSTOMER_SERVICE', 'TEAM_LEADER'].includes(person.role) && <Fragment>
					{creators.length > 0 && <p>Toegevoegd door: {[...new Set(creators.map(c => c.name))].join(', ')}</p>}
					<div className="row">
						<div className="col-4">
							{buttons.length > 0 && <p>
								{buttons}
							</p>}
							<label className="d-block">
								Pakketdienst: <input type="checkbox" checked={order.dropship} onChange={this.toggleDropship} />
							</label>
							{person.role === 'CUSTOMER_SERVICE' && !order.dropship && <label>
								Max. pallets: <input
									type="number"
									className="form-control"
									min="1"
									value={this.state.pallets}
									onChange={this.setPallets} />
							</label>}
						</div>
						<div className="col-8">
							{(!order.dropship || abc.length === 1) && <Address order={order} className="text-right" />}
						</div>
					</div>
				</Fragment>}
				{person.role === 'PACKAGER' && <div className="row">
					<div className="col-4">
						{order.status === 'SEALING' && <button className="btn btn-primary" onClick={this.printStickers} disabled={this.state.printing}>Print stickers</button>}
					</div>
					<div className="col-8">
						<p className="text-right">{order.customer.code} {order.customer.name}</p>
						<Address order={order} className="text-right" />
					</div>
				</div>}
				{person.role === 'CUSTOMER_SERVICE' && !states.FAILED && <p>
					Selecteer alle regels <Switch
						value={!states.NONE}
						onChange={e => {
							this.toggleAllLines(e.target.checked ? 'SUCCESS' : 'NONE');
						}} />
				</p>}
				<div className="row">
					<div className="col-6"><p>
						{person.role !== 'PACKAGER' && <Fragment>{cbcmToCbm(size)} m<sup>3</sup>, {inPallets(size)} pallet(s)<br /></Fragment>}
						Locatie: <strong style={{ color: randomcolor({ seed: `${order.location}` })}}>{order.location}</strong>
					</p></div>
					<div className="col-6">{status}</div>
				</div>
				{order.status !== 'DONE' && person.role === 'CUSTOMER_SERVICE' && !order.dropship && <DeliveryForm
					autoSave order={order} abc={abc} />}
				<OrderFields order={order} abc={abc} readonly={order.dropship ? person.role !== 'PACKAGER' || order.status !== 'ON_PALLET' : person.role !== 'TEAM_LEADER'}>
					<ol className="list-unstyled">
						{Object.keys(locations).map(splitLocation).sort(sortLocation).map(place => {
							const seed = `${place.loods}`;
							const classes = ['order-line'];
							if(seed !== prev) {
								classes.push('first');
							}
							prev = seed;
							return <li key={place.location} style={{ borderColor: randomcolor({ seed }) }} className={classes.join(' ')}>
								{locations[place.location].map(({ line, quantity, order: o }) => <OrderLine
									key={`${place.location}_${line._id}_${o._id}`}
									role={person.role}
									order={o}
									quantity={quantity}
									place={place.location}
									location={stringifyLocation(place)}
									line={line} />)}
							</li>;
						})}
					</ol>
					{status}
				</OrderFields>
			</div>
		</section>;
	}
}

export default function Page(props) {
	return <AbcOrder {...props} component={PlanPage} />;
}
